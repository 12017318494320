<template>
  <!--商品详情页-->
  <div class="shop-det">
    <div class="shop-top-nav">
      <span class="iconfont iconleftbutton-fill" @click="goBack"></span>
      <div class="shop-top-nav-center">
        <span
          v-for="(item, idx) in navTar"
          :class="[ idx == idxAct ? 'active' : '' ] "
          :key="idx"
          @click="actItem(idx)"
        >{{item}}</span>
      </div>
      <div>
        <span v-if="showStore" class="iconfont iconshoucang" @click="love_store(goodsDetail)" style="margin-right:10px;"></span>
        <span class="iconfont iconiconfontgengduo"></span>
      </div>
    </div>
    <BetterScroll
      class="wrapper"
      ref="wrapper"
      :probeType="3"
      :bounce="bounce"
      :click="true"
      :listenScroll="true"
      v-on:scroll="listenScroll"
    >
      <div class="main-center">
        <div class="shop-det-baner">
          <swipe :autoplay="3000" style="height:100%">
            <swipe-item v-for="(image, index) in goodsDetail.image" :key="index">
              <img v-lazy="image.url" />
            </swipe-item>
          </swipe>
        </div>

        <div class="shop-det-text-con">
          <div class="shop-det-pic">
            <div>
              ￥
              <span class="new-pic">{{goodsDetail.price}}</span>
              <span class="old-pic">￥{{goodsDetail.mktprice}}</span>
            </div>
            <span>月销 {{goodsDetail.sale_num}}</span>
          </div>
          <div class="shop-det-text-maintext">{{goodsDetail.name}}</div>
        </div>
       <div class="det-textcon">
          <!-- <Celldatail leftText="促销" @click.native="promotion.status = true" centerCon="假一赔四丨7天无理由退货丨消费者保障服务"></Celldatail> -->
          <Celldatail
            leftText="领券"
            :centerCon="goodsOther.coupon_status ? '您有优惠券尚未领取':'暂无优惠券'"
            @click.native="showModel('ticket')"
          ></Celldatail>
        </div>
        <div class="det-textcon">
          <Celldatail
            leftText="保障"
            :centerCon="goodsOther.serviceStr"
            @click.native="showModel('safe')"
          ></Celldatail>
          <Celldatail leftText="参数" :centerCon="goodsOther.paramsStr" @click.native="showModel('parameter')"></Celldatail>
        </div> 
        <div class="det-textcon">
          <!-- <Celldatail leftText="已选" :centerCon="goods_spes ? goods_spes:'未选择' "></Celldatail> -->
          <Celldatail
            leftText="送至"
            @click.native="address = true"
            :centerCon="myaddress.init_address"
          ></Celldatail>
          <Celldatail leftText="运费" :centerCon="goodsDetail.freight ? '该商品商家包邮哦':`${goodsDetail.freight}元`"></Celldatail>
        </div>
        <!-- 商品评价 -->
        <shopEvaluate :id="$route.query.goods_id"></shopEvaluate>

        <!--店铺入口-->
        <StoreEnter v-if="showStore" :storeInfo="goodsDetail.store"></StoreEnter>
        <!-- 店铺信息 -->
        <div class="shop-det-text-diytext" v-html="goodsDetail.intro"></div>
        <!--推荐列表-->
        <div class="shop-title">
          <Divider :style="{ color: '#333333', borderColor: '#8c8c8c', padding: '0 16px' }">看了又看</Divider>
        </div>
        <ShopList type="other" style="padding-top:0" :showTabs="false" :url_status="false"></ShopList>
      </div>
    </BetterScroll>

    <!--底部购买组件-->
    <StorePay :storeIcon="showStore" v-if="button_status" :buyObj="goodsDetail"></StorePay>

    <!--促销-->
    <Popup
      v-model="promotion.status"
      position="bottom"
      closeable
      :style="{ height: 'auto', padding: '0, 10px' }"
    >
      <div class="model-title">促销</div>
      <div class="cx-list-con">
        <div class="cx-list-item">
          <div class="cx-list-left">
            <span class="cx-list-label">积分</span>
          </div>
          <div class="cx-list-text">购买可得200积分</div>
        </div>
        <div class="cx-list-item">
          <div class="cx-list-left">
            <span class="cx-list-label">满减</span>
          </div>
          <div class="cx-list-text">满200元减10元；满500元减30元</div>
        </div>
        <div class="cx-list-item">
          <div class="cx-list-left">
            <span class="cx-list-label">赠品</span>
          </div>
          <div class="cx-list-text">高品质、品牌蓝牙耳机（赠品颜色款式随机）（此 赠品只限购本店商品才有，与平台方无关）×1</div>
        </div>
      </div>

    </Popup>

    <!--优惠券-->
    <Popup
      v-model="ticket"
      position="bottom"
      closeable
      :style="{ height: '60%', padding: '0, 10px' }"
    >
      <div class="model-title">领券</div>
      <div class="ticket-list" v-for="(item, idx) in goodsOther.coupon" :key="idx">
        <div class="ticket-left-text">
          <span>
            <span v-if="item.type == 1 || item.type == 3">￥</span>
            <span class="sub-money" v-if="item.type == 1">{{item.m_dec_money}}</span>
            <span class="sub-money" v-if="item.type == 2">{{item.zhe_discount}}</span>
            <span class="sub-money" v-if="item.type == 3">{{item.d_face_value}}</span>
            <span>{{item.type | card_type}}</span>
          </span>
          <span>{{item.title}}</span>
          <span>{{item.term_start_time}}-{{item.term_end_time}}</span>
        </div>
        <div class="ticket-right-btn">
          <div class="left-line"></div>
          <div class="get-status" v-if="item.status">立即领取</div>
          <div class="getOverList" v-if="!item.status">已领取</div>
          <div class="get-over-status" v-if="!item.status">
            <img src="@static/home/get_over_status.png" alt="">
          </div>
        </div>
      </div>
    </Popup>
    <!--保障-->
    <Popup
      v-model="safe"
      position="bottom"
      closeable
      :style="{ height: 'auto', padding: '0, 10px' }"
    >
      <div class="model-title">保障</div>
      <div class="safe-list" v-for="(item, idx) in goodsOther.goods_service" :key="idx">
        <div class="safe-left-text">
          <span class="iconfont iconicon--yes" style="color:#FF8000"></span>
          <span>{{item.parent.name}}</span>
        </div>
        <div class="safe-bot-btn">
          <div class="getOverList">{{item.name}}</div>
        </div>
      </div>
    </Popup>
    <!--商品参数-->
    <Popup
      v-model="parameter"
      position="bottom"
      closeable
      :style="{ height: 'auto', padding: '0, 10px' }"
    >
      <div class="model-title">商品参数</div>
      <div class="parameter-list" v-for="(item, idx) in goodsOther.goods_params" :key="idx">
        <span>{{item.parent.name}}</span>
        <span>{{item.name}}</span>
      </div>
    </Popup>
    <!--选择地址-->
    <Popup
      v-model="address"
      position="bottom"
      closeable
      :style="{ height: '60%', padding: '0, 10px' }"
    >
      <div class="model-title">选择地址</div>
      <template v-if="myaddress.status">
        <div class="cx-addres" 
          v-for="(adress_item, idx) in myaddress.address_list"
          @click="changeAddress(adress_item,idx)"
          :key="idx">
          <span :class="[myaddress.now_idx==idx? 'iconcheck1':'iconaddress icon-active' ,'iconfont']"></span>
          <span :class="[myaddress.now_idx==idx? 'active':'']">{{adress_item.area_name}}</span>
        </div>
      </template>
      <div v-else class="no-address">
        <img src="@static/vip/no_address.png" alt />
      </div>
      <div class="add-btn" @click="addUserAddress">添加地址</div>
    </Popup>
  </div>
</template>
<script>
import { Divider, Popup, Swipe, SwipeItem } from "vant";
import BetterScroll from "@components/public/BetterScroll";
import StoreEnter from "@components/public/StoreEnter";
import Celldatail from "@components/public/celldatail";
import shopEvaluate from "@components/public/shopEvaluate";
import StorePay from "@components/public/StorePay";
import ShopList from "./ShopList";
import Goods from "@/api/goods";
import Store from "@/api/store";
import Address from "@/api/address";

export default {
  components: {
    Celldatail,
    Divider,
    ShopList,
    StoreEnter,
    StorePay,
    Popup,
    Swipe,
    SwipeItem,
    BetterScroll,
    shopEvaluate
  },
  filters: {
    card_type: function(val) {
      switch (val) {
        case 1:
          return '满减券';
        case 2:
          return '折扣券';
        case 3:
          return '代金券';
        default:
          break;
      }
    }
  },
  data() {
    return {
      commodityId: this.$route.query.id,
      idxAct: 0,
      scroll: "",
      scrollTop: 0,
      ticket: false,   // 优惠券弹窗
      safe: false,     // 保障弹窗
      parameter: false,// 参数弹窗
      address: false,  // 地址弹窗
      navTar: ["商品", "详情", "评价", "推荐"],
      goodsDetail: {},
      button_status: false,
      showStore: false,
      goods_spes: "",
      promotion: {     // 促销
        status: false,
      }, 
      myaddress: {     // 地址
        now_idx: 0,
        status: false,
        init_address: "",
        address_list: [],
      },
      bounce: {
        top: false // 是否回弹
      },
      objHeight: {
        navTop: 0,
        bannerH: 0
      },
      goodsOther: {
        paramsStr: "",
        serviceStr: "",
        goods_params: [],
        goods_service: [],
        coupon:[],
        coupon_status: false
      }
    };
  },
  methods: {
    // 顶部tab切换
    actItem(idx) {
      this.idxAct = idx;
      switch (idx) {
        case 0:
          this.$refs.wrapper.scrollTo(0, 0, 500);
          break;
        case 1:
          this.$refs.wrapper.scrollTo(0, -218, 500);
          break;
        case 2:
          this.$refs.wrapper.scrollTo(0, -417, 500);
          break;
        case 3:
          this.$refs.wrapper.scrollTo(0, -712, 500);
          break;  
        default:
          break;
      }
    },
    // 设置选择多规格内容
    set_Spec(val) {
      this.goods_spes = val;
    },
    // 收藏店铺
    async love_store(item) {
      try {
        const res = await Store.addStoreLove(item.store_id);
        if (res.code == 200) {
          this.$toast(res.msg);
          this.love_status = false;
        } else {
          throw(res.msg);
        }
      } catch (error) {
        this.$toast(error);
      }
    },
    // 获取详细信息
    async getDetail() {
      try {
        const res = await Goods.getGoodsDetail(this.$route.query.goods_id);
        if (res.code == 200) {
          this.goodsDetail = res.data;
          this.button_status = true;
          if (res.data.store.length > 0) this.showStore = true; 
          var result = res.data;
          if (result.coupon.result.length > 0) {
            this.goodsOther.coupon = result.coupon.result;
            this.goodsOther.coupon_status = Number(result.coupon.num) > 0 ? true:false
          }
          if (result.goods_params.length > 0) {
            let arr = [];
            for (let item in result.goods_params) {
              arr.push(result.goods_params[item].name)
            }
            this.goodsOther.goods_params = result.goods_params;
            this.goodsOther.paramsStr = arr.join(',');
          } else {
            throw("无商品参数")
          }
          if (result.goods_service.length > 0) {
            let arr = [];
            for (let item in result.goods_service) {
              arr.push(result.goods_service[item].name)
            }
            this.goodsOther.goods_service = result.goods_service;
            this.goodsOther.serviceStr = arr.join(',');
          } else {
            throw("无服务参数")
          }
        }
      } catch(err) {
        console.log(err);
      }
    },
    // 导航栏颜色变化
    scrollDs() {
      let el = document.querySelector(".shop-top-nav");
      let num = 0;
      if (this.scrollTop > 45) {
        setTimeout(() => {
          num++;
          if (num > 1) num = 1;
          el.classList.add("animated", "fadeIn");
          el.classList.remove("fadeOut");
          el.style.backgroundColor = `rgba(255,255,255, ${num})`;
        }, 1000);
      } else {
        setTimeout(() => {
          el.classList.remove("fadeIn");
          num--;
          el.style.backgroundColor = `rgba(255,255,255, ${num})`;
        }, 1000);
      }
    },
    // 显示功能 Model
    showModel(status) {
      switch (status) {
        case "ticket":
          this.ticket = true;
          break;
        case "safe":
          this.safe = true;
          break;
        case "parameter":
          this.parameter = true;
          break;
        default:
          break;
      }
    },
    // 添加用户地址
    addUserAddress() {
      this.$router.push({
        path: "/addAddress",
        qurey: {
          change: false
        }
      });
    },
    // 获取地址列表
    async getInitAddress() {
      try {
        const res = await Address.getAddressList();
        if (res.code == 200) {
          if (Object.keys(res.data).length) {
            this.myaddress.status = true;
            this.myaddress.address_list = res.data;
            this.myaddress.init_address = res.data[0].area_name + res.data[0].address;
          }
        }
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    // 地址切换
    async changeAddress(item, idx) {
      this.myaddress.now_idx = idx;
      this.myaddress.init_address = item.area_name + item.address;
      this.address = false;
      // 设置默认地址
      try {
        const res = await Address.setInitAddress(item.id);
        if (res.code != 200) {
          throw(res.rsg);
        }
      } catch(err) {
        console.log(err);
      }
    },
    // 监听scroll事件
    listenScroll(pos) {
      if (pos.y < 0) {
        this.scrollTop = -pos.y;
      }
      this.$nextTick(() => {
        // 顶部导航
        this.scrollDs();
        // 滑动切换顶部tab
        this.changTab();
      }, 50);
    },
    // 动态切换顶部Tab
    changTab() {
      if (this.scrollTop > 0 && this.scrollTop < 218) {
        this.idxAct = 0;
      } else if (this.scrollTop > 218 && this.scrollTop < 417) {
        this.idxAct = 1;
      } else if (this.scrollTop > 417 && this.scrollTop < 712) {
        this.idxAct = 2;
      } else if (this.scrollTop > 712) {
        this.idxAct = 3;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.objHeight.navTop = document.querySelector(
        ".shop-top-nav"
      ).offsetHeight;
      this.objHeight.bannerH = document.querySelector(
        ".shop-det-baner"
      ).offsetHeight;
      this.getDetail();
      this.getInitAddress();
    }, 500);
  }
};
</script>

<style lang="scss">
.shop-det {
  width: 100%;
  height: auto;
  .wrapper {
    width: 100%;
    height: 100vh;
    background: #f4f4f4;
    overflow: hidden;
    .main-center {
      width: 100%;
      height: auto;
    }
  }
  .shop-top-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    padding: 0 10px;
    font-size: 16px;
    height: 45px;
    line-height: 45px;
    justify-content: space-between;
    background: #f4f4f4;
    z-index: 100;
    .iconfont {
      font-size: 24px;
    }
    .shop-top-nav-center {
      width: 50%;
      display: flex;
      color: #333333;
      justify-content: space-between;
    }
    .active {
      color: #ff8000;
      border-bottom: 1px solid #ff8000;
    }
  }
  .shop-det-baner {
    width: 100%;
    height: 260px;
    position: relative;
    background: #ffffff;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .det-textcon {
    width: 100%;
    height: auto;
    // margin-top: 10px;
  }
  .model-title {
    width: 100%;
    height: 45px;
    font-size: 16px;
    line-height: 45px;
    text-align: center;
    color: #333333;
    border-bottom: 1px solid #dfdfdf;
  }
  .cx-addres {
    width: 100%;
    height: 26px;
    line-height: 16px;
    font-size: 12px;
    padding: 0 10px;
    display: flex;
    margin-top: 18px;
    align-items: center;
    span:nth-child(1) {
      color: #FF8000;
    }
    .icon-active {
      color: #8C8C8C !important;
    }
    .active,span:nth-child(2) {
      color: #8C8C8C;
      padding-left: 6px;
    }
    .active {
      color: #333333 !important;
    }
  }
  .cx-list-con {
    width: 100%;
    padding: 0 10px 20px 10px;
    .cx-list-item {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .cx-list-left {
        width: 50px;
        .cx-list-label {
          color: #FF8000;
          border-radius: 3px;
          padding: 2px 4px;
          background: #FEF5F0;
        }
      }
      .cx-list-text {
        flex: 1;
        color: #333333;
      }
    }
  }
  .ticket-list {
    width: 96%;
    height: auto;
    margin: 10px auto;
    display: flex;
    align-items: center;
    color: #ff8000;
    overflow: hidden;
    justify-content: space-between;
    .ticket-left-text {
      flex: 1;
      height: 80px;
      display: flex;
      padding: 10px 10px;
      background: #fef5f0;
      border-radius: 10px;
      flex-direction: column;
      .sub-money {
        font-size: 20px;
      }
    }
    .ticket-right-btn {
      position: relative;
      width: 102px;
      height: 80px;
      padding: 0 10px;
      display: flex;
      border-radius: 10px;
      background: #fef5f0;
      align-items: center;
      justify-content: center;
      z-index: 100;
      .get-status, .getOverList {
        width: 80px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        background: rgba(255, 128, 0, 1);
        color: #ffffff;
        border-radius: 15px;
      }
      .getOverList {
        border: 1px solid #ff8000;
        color: #ff8000;
        background: transparent;
      }
    }
    .get-over-status {
      width: 80px;
      height: 80px;
      left: 60px;
      bottom: -40px;
      z-index: -10;
      position: absolute;
      img {
        width: 70%;
        height: 70%;
      }
    }
    .left-line {
      position: absolute;
      height: 70px;
      left: 0;
      border-left:1px dashed rgba(255,185,153,1);
      background:rgba(255,237,230,1);
    }
  }
  .safe-list {
    width: 96%;
    height: 60px;
    margin: 10px 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    border-bottom: 1px solid #f4f4f4;
    .safe-left-text {
      margin-left: -10px;
      span {
        margin-left: 10px;
      }
    }
  }
  .parameter-list {
    width: 96%;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    margin: 0 auto;
    border-bottom: 1px solid #f4f4f4;
    span:nth-child(1) {
      color: #8c8c8c;
    }
    span:nth-child(2) {
      color: #333333;
      margin-left: 10px;
    }
  }
  .no-address {
    width: 92%;
    height: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
    }
  }
  .add-btn {
    width: 92%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    position: absolute;
    left: 50%;
    margin-left: -46%;
    bottom: 20px;
    background: linear-gradient(
      -90deg,
      rgba(255, 144, 0, 1) 0%,
      rgba(255, 177, 1, 1) 100%
    );
    border-radius: 8px 8px 8px 8px;
  }
  .shop-title {
    width: 100%;
    height: 45px;
    background: #ffffff;
    line-height: 45px;
    margin-top: 10px;
    padding: 1px 20% 0 20%;
  }
  .shop-det-text-con {
    width: 100%;
    min-height: 80px;
    padding: 8px 12px;
    padding-bottom: 0;
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;
    background: #ffffff;
    .shop-det-pic {
      line-height: 22px;
      display: flex;
      color: #ff8000;
      justify-content: space-between;
      div {
        .new-pic {
          font-size: 24px;
        }
        .old-pic {
          margin-left: 10px;
          color: #999999;
          text-decoration: line-through;
        }
      }
    }
    .shop-det-text-maintext {
      width: 88%;
      color: #333333;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
    }
    .shop-det-text-diytext {
      margin-top: 6px;
      line-height: 15px;
      font-size: 12px;
      color: #999999;
    }
  }
}
</style>