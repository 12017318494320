<template>
  <div class="back">
    <div class="shopEvaluate">
      <div class="title">
        <span class="title-lft">用户评价(<span style="color:#FF8000;font-size:10px">{{commitList.length}}</span>)</span>
        <!-- <span class="title-rgt">5.0评分〉</span> -->
      </div>
      <div class="content" v-for="(item, index) in commitList" :key="index">
        <div class="top">
          <div class="top-lft">
            <div class="header">
              <img :src="item.user.headimgurl" alt="" v-if="item.user.headimgurl">
              <img src="@static/home/photo.png" alt="" v-else>
            </div>
            <div class="name">
              <span style="font-weight: bold">{{item.user.username}}</span>
              <rate v-model="item.score" size="12" readonly/>
            </div>
          </div>
          <div class="top-rgt">
            <span class="time">{{item.create_time}}</span>
          </div>
        </div>
        <div class="text">
          <span>{{item.content}}</span>
        </div>
        <div class="btm">
          <swipe :loop="false" :width="120" :show-indicators="false">
            <swipe-item v-for="(img, idx) in item.images" :key="idx"><img :src="img" alt=""></swipe-item>
          </swipe>
        </div>
      </div>
      <div class="btn" @click="allEvaluate"><span>查看全部</span></div>
    </div>
  </div>
</template>

<script>
import Goods from "@api/goods";
import { Swipe, SwipeItem, Rate } from 'vant';
export default {
  props: ["id"],
  components: {
    Rate,
    Swipe,
    SwipeItem
  },
  data() {
    return {
      value: 2,
      commitList: []
    }
  },
  methods: {
    async getComment() {
      try {
        const res = await Goods.getComment(this.id, 1, 2);
        if (res.data.length > 0) {
          this.commitList = res.data;
        }
        console.log(res);
      } catch(err) {
        console.log(err)
      }
    },
    allEvaluate() {
      console.log(this)
      this.$router.push({
        path: "/shopEvaluate",
        query: {
          id: this.id
        }
      })
    }
  },
  mounted() {
    this.getComment();
  }
}
</script>

<style lang="scss" scoped>
.back {
  padding: 5px 0;
  background: #F4F4F4;
}
.shopEvaluate {
  background: #fff;
  padding: 12px;
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .title-lft {
      font-weight: bold;
      font-size: 14px;
    }
  }
  .content {
    margin-top: 8px;
    border-bottom: 1px solid #dbdbdb3d;
    padding-bottom: 10px;
    .top {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      .top-lft {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .header {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .name {
          display: flex;
          flex-direction: column;
        }
      }
      .top-rgt {
        color: #8C8C8C;
      }
    }
    .text {
      margin-top: 6px;
      line-height: 18px;
    }
    .btm {
      margin-top: 6px;
      // height: 85px;
      img {
        width: 90%;
      }
    }
  }
  .btn {
    width:80px;
    height:21px;
    border:1px solid rgba(128,128,128,1);
    text-align: center;
    line-height: 21px;
    font-size: 14px;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 10px;
  }
}
</style>

<style lang="scss">
.shopEvaluate {
  .van-swipe {
    height: 100%;
  }
}
</style>