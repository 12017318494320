<template>
  <!--详情页店铺入口-->
  <div class="shop-store">
    <div class="shop-store-top">
      <div class="shop-store-img">
        <img :src="storeInfo.logo" alt="">
      </div>
      <div class="shop-store-center">
        <span>{{storeInfo.name}}</span>
        <span class="shop-store-fen">
          <Rate v-model="starts"  :size="12"></Rate> 4分丨1.5万人收藏
        </span>
      </div>
      <span class="iconfont iconqianjin"></span>  
    </div>
    <div class="shop-store-main">
      <div class="shop-store-item">
        <span>商品描述</span> <span class="store-pingfen">4.5</span> <span class="store-status">高</span>
      </div>
      <div class="shop-store-item">
        <span>商家服务</span> <span class="store-pingfen">4.5</span> <span class="store-status">低</span>
      </div>
      <div class="shop-store-item">
        <span>订单时效</span> <span class="store-pingfen">4.5</span> <span class="store-status">高</span>
      </div>
    </div>
    <div class="shop-store-btn">
      <div>联系客服</div>
      <div @click="storedet">进店逛逛</div>
    </div>
  </div>
</template>
<script>
import { Rate } from 'vant';
export default {
  components: {
    Rate
  },
  props: ['storeInfo'],
  data() {
    return {
      starts: 4
    }
  },
  methods: {
    storedet() {
      this.$router.push(`/storedet?merch_id=${this.storeInfo.merch_id}&store_id=${this.storeInfo.store_id}`);
    }
  }
}
</script>
<style scoped>

</style>
<style lang="scss" scoped>
.shop-store {
  width: 100%;
  height: 134px;
  margin-top: 10px;
  background:#ffffff;
  .shop-store-main {
    width: 100%;
    height: 20px;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    font-size: 12px;
  }
  .shop-store-btn {
    width: 100%;
    height: 52px;
    line-height: 52px;
    display: flex;
    justify-content: space-between;
    padding: 18px 85px;
    div {
      width: 85px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      align-items: center;
      color: #ffffff;
      border-radius: 12px;
      background: linear-gradient(-90deg,rgba(255,128,0,1) 0%,rgba(255,177,1,1) 100%);
    }
  }
  .shop-store-top {
    width: 100%;
    height: 60px;
    display: flex;
    padding: 14px 10px;
    justify-content: space-between;
    align-items: center;
    .shop-store-center {
      width: 80%;
      color: #333333;
      display: flex;
      padding-left: 10px;
      flex-direction: column;
      .shop-store-fen {
        margin-top: 4px;
        color: #4F4F4F;
        font-size: 12px;
      }
      span {
        font-size: 14px;
        color: #333333;
      }
    }
    .shop-store-img {
      width: 45px;
      height: 45px;
      border-radius: 3px;
      background: #333333;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
<style scoped>
.shop-store-item:nth-child(2n+1) >  .store-pingfen {
  color: #FF8000;
}
.shop-store-item:nth-child(2n+1) >  .store-status {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  background: #FF8000;
  color: #ffffff;
}
.shop-store-item:nth-child(2n) >  .store-pingfen {
  color: #3DBC12;
}
.shop-store-item:nth-child(2) >  .store-status {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  background: #3DBC12;
  color: #ffffff;
}
</style>