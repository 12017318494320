<template>
  <div>
    <GoodsAction style="z-index:100">
      <GoodsActionIcon v-if="storeIcon" icon="shop-o" text="店铺" @click="goStore"/>
      <!-- <GoodsActionIcon icon="chat-o" text="客服" /> -->
      <GoodsActionIcon icon="cart-o" text="购物车" @click="toShopCar"/>
      <GoodsActionButton type="warning" text="加入购物车" @click="showSku = true"/>
      <GoodsActionButton type="danger" text="立即购买" @click="buyGoods"/>
    </GoodsAction>
    <Popup v-model="showSku" round :style="{ height: `${setBottomH}%` }" position="bottom">
      <div class="sku-top">
        <div class="sku-top-left">
          <img :src="goodsObj.image[0].url" alt="">
        </div>
        <div class="sku-price">
          <p class="sku-name">{{buyObj.name}}</p>
          <p>
            <span>￥ </span>    
            <span style="font-size:16px">{{goodsObj.price}}</span>
          </p>
          <p>库存{{goodsObj.stock}}件</p>
          <p v-if="buyObj.is_spec">已选：{{good_desc}}</p>
        </div>
      </div>
      <div class="goodsname-con" v-if="buyObj.product.length">
        <span 
          v-for="(item, idx) in buyObj.spes_desc"
          :class="[ idx == nowGood ? 'active':'', 'goodsname' ]"  
          :key="idx" @click="setGood(item, idx)">
          {{item}}
        </span>
      </div>
      <div class="buynum">
        <span>购买数量</span>
        <Stepper v-model="buynumber"></Stepper>
      </div>
      <div class="sku-btn" v-if="typeStatus">
        <div @click="addCar">加入购物车</div>
        <div @click="goPay">立即购买</div>
      </div>
      <div class="sku-btn2" @click="addCar" v-if="!typeStatus">
        <span>确认</span>
      </div>
    </Popup>
  </div>
</template>
<script>
import Goods from "@/api/goods";
import { GoodsAction, GoodsActionIcon, GoodsActionButton, Popup, Stepper } from "vant";
export default {
  components: {
    GoodsAction,
    GoodsActionIcon,
    GoodsActionButton,
    Popup,
    Stepper
  },
  props: {
    buyObj: {
      type: Object
    },
    storeIcon: {
      type: Boolean,
    }
  },
  data() {
    return {
      showSku: false,
      goodsObj: {},
      nowGood: 0,
      buynumber: 1,
      typeStatus: false,
      newProId: "",
      good_desc: "",
      setBottomH: "50"
    }
  },
  methods: {
    // 显示商品规格
    buyGoods() {
      this.showSku = true;
      this.typeStatus = true;
    },
    // 添加到购物车
    async addCar() {
      this.typeStatus = false;
      let productId = this.buyObj.product[0].id;
      if (this.newProId) {
        productId = this.newProId;
      } else {
        productId = this.buyObj.product[0].id;
      }
      this.showSku = true;
      try {
        const res = await Goods.addShopCar(this.buyObj.id, productId, this.buynumber);
        if (res.code == 200) {
          this.$toast(res.msg);
          this.showSku = false;
        } else {
          throw(res.msg);
        }
      } catch (err) {
        this.$toast(err);
      }
    },
    // 去购买
    goPay() {
      this.$store.commit("setAddressId", "");
      this.$router.push({
        path: "/makeorder",
        query: {
          goodsid: this.buyObj.id,
          productid: this.goodsObj.id,
          num: this.buynumber
        }
      });
    },
    // 设置商品内容
    setGood(item, idx) {
      console.log(item);
      // this.goodsObj = item;
      this.nowGood = idx;
      this.$parent.set_Spec(item);

      this.good_desc = item;
    },
    // 去购物车
    toShopCar() {
      this.$router.push({
        path: "/shopping"
      })
    },
    goStore() {
      // merch_id  是商家  store_id 店铺
      this.$router.push({
        path: `/storedet?merch_id=${this.buyObj.merch_id}&store_id=${this.buyObj.store_id}`
      })
    }
  },
  created() {
    try {
      console.log(this.buyObj.spes_desc);
      if (this.buyObj.spes_desc) {
        this.good_desc = this.buyObj.spes_desc[0];
        this.goodsObj = this.buyObj.product[0];
      } else {
        this.setBottomH = "32";
        this.goodsObj = this.buyObj.product[0];
        throw("无多规格信息..");
      }
    } catch (err) {
      console.log(err);
    }
  }
};
</script>
<style lang="scss" scoped>
.sku-top {
  width: 100%;
  height: auto;
  display: flex;
  padding: 20px 10px;
  justify-content: space-between;
  .sku-name {
    font-size: 16px;
    font-weight: 500;
  }
  .sku-top-left {
    width: 100px;
    height: 100px;
    background: #FF8000;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .sku-price {
    flex: 1;
    padding-top: 5px;
    padding-left: 10px;
    p:nth-child(2) {
      margin-top: 8px;
      color: #FF8000; 
    }
    p:nth-child(3), p:nth-child(4) {
      color: rgb(29, 12, 12); 
      padding-top: 4px;
    }
  }
}
.goodsname-con {
  width: 100%;
  height: auto;
  display: flex;
  padding: 0 16px;
  margin-top: 20px;
  margin-left: -6px;
  justify-content: flex-start;
  .goodsname {
    width: auto;
    display: inline-block;
    height: 28px;
    padding: 5px 8px;
    line-height: 28px;
    text-align: center;
    border-radius: 6px;
    margin-left: 6px;
    background:rgba(244,244,244,1);
  }
  .active {
    background:rgba(254,245,240,1);
    color: #FF8000;
  }
}

.buynum {
  width: 100%;
  padding: 0 20px;
  height: 50px;
  line-height: 50px;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 44px;
  justify-content: space-between;
}
.sku-btn {
  width: 100%;
  height: 44px;
  line-height: 44px;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  div {
    flex: 1;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    background-color: #FFB101;
  }
  div:nth-child(2) {
    background-color: #FF8000;
  }
}
.sku-btn2 {
  width: 100%;
  height: 44px;
  line-height: 44px;
  display: flex;
  position: fixed;
  bottom: 0;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  justify-content: center;
  background-color: #FFB101;
}
</style>