<template>
  <!--商品详情banner下方组件-->
  <div class="cell-list">
    <div :class="[border ? 'pub-border':'', 'cell-list-con']">
      <span class="cell-list-title">{{leftText}}</span>
      <div>{{centerCon}}</div>
      <Icon name="weapp-nav"></Icon>
    </div>
  </div>
</template>
<script>
import { Icon } from "vant"
export default {
  components: {
    Icon
  },
  props: {
    leftText: {
      type: String,
      default: "左边"
    },
    centerCon: {
      type: String,
      default: "中间内容"
    },
    border: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      borderStatus: {
        borderBottem: "1px solid #f4f4f4;"
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.pub-border {
  border-bottom: 1px solid #f4f4f4;
}
.cell-list {
  width: 100%;
  min-height: 40px;
  background: #ffffff;
  .cell-list-con {
    width: 92%;
    height: 40px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: space-between;
  }
  .cell-list-title {
    font-size: 12px;
    color: #8C8C8C;
  }
  div {
    width: 80%;
    overflow-wrap: break-word;
    color: #333333;
  }
  
}
</style>